<template>
  <div>
    <div class="columns is-multiline">
      <div class="column">
        <explorer-keyword />
      </div>
      <div class="column is-narrow">
        <explorer-outlier-toggle />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExplorerFilters',
  components: {
    explorerOutlierToggle: () => import('./ExplorerOutlierToggle'),
    explorerKeyword: () => import('./ExplorerKeyword')
  }
}
</script>
